@import "./convertPxToVw.scss";

.App {
  text-align: center;
  font-family: "Palanquin Dark";
  overflow-x: hidden;
}

.btn-black {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background-color: #000000;
  border-radius: 0.88vw;
  background: linear-gradient(94.21deg, #252424 4.48%, #424242 87.94%);
}
@font-face {
  font-family: Matahari800;
  src: url("./assets/Mathari/Matahari-800ExtraBold.otf") format("opentype");
}
@font-face {
  font-family: Matahari700;
  src: url("./assets/Mathari/Matahari-700Bold.otf") format("opentype");
}
/* manually setting margin to accomodate the negative margin being set to middle item in the slide */
.carousel__slider-tray-wrapper.carousel__slider-tray-wrap--horizontal {
  margin-top: 3vh;
}
/* setting margin to middle item in the current slide */
.magnify.imagex.carousel {
  transition: 1000ms;
  margin-top: -3vh;
  width: 14.7vw;
  height: 14.7vw;
}

/* REACT MODAL */

.ReactModal__Overlay--after-open {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: 11;
}

.ReactModal__Content--after-open {
  border: none !important;
  background: unset !important;
  overflow: auto !important;
  border-radius: unset !important;
  outline: none !important;
  padding: 2.5vw !important;
}
@media screen and (max-width: 600px) {
  .Toastify__toast {
    border-radius: 15px !important;
    margin-top: 6px !important;
    margin-left: 35% !important;
    min-height: unset !important;
    padding: 3vw 3.5vw !important;
    max-width: 60vw !important;
    align-items: center;
    justify-content: center;
  }
}

.slick-list {
  padding: 85px 50px !important;
}

.slick-track {
  display: flex !important;
  align-items: flex-start;
  // transition: all 0.1s cubic-bezier(0.81, 0.01, 0.15, 0.99) !important;
}

.slick-active {
  // width: 260px !important;
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.slick-current {
  // width: 45vw !important;
  margin-left: 40px !important;
  margin-right: 40px !important;
}

@media screen and (max-width: 600px) {
  .slick-list {
    padding-bottom: 0px !important;
  }

  .slick-active {
    // width: 260px !important;
    margin-left: unset !important;
    margin-right: unset !important;
    opacity: 1 !important;
  }
  .slick-slide {
    opacity: 0.5;
  }

  .slick-slide {
    min-height: 60vh !important;
  }

  .slick-current {
    // width: 45vw !important;
    margin-left: unset !important;
    margin-right: unset !important;
  }
}
