@import "../../convertPxToVw.scss";

.Home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1vw;
}

.Layer1 {
  width: vw(2685px);
  height: vw(996px);
  position: absolute;
  left: -30vw;
  top: -10vw;
  animation: lightPulsate 1s ease-in-out infinite alternate-reverse;
}

.Layer2 {
  width: 100vw;
  height: 120vh;
  position: absolute;
  left: 0;
  z-index: -1;
}

.Lungs {
  width: vw(1500px);
  position: absolute;
  left: 0;
  top: -40vw;
  left: 13vw;
  animation: pulsate 1s ease-in-out infinite alternate;
  // z-index: 1;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.02);
  }
}
@keyframes lightPulsate {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.HeaderContainer {
  height: 104vh;
  position: relative;
  top: vw(-81px);
  z-index: 1;
}

.HeaderContainer video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  object-fit: cover;
  z-index: -5;
}

.ContactUsContainer {
  height: 100vh;
  position: relative;
  z-index: 100;
  transition: display 1s ease-out;

  .TaglineButton {
    width: fit-content;
    padding: vw(20px) vw(16px);
    color: white;
  }

  .Tagline {
    margin: auto;
    padding-top: vw(316px);
    padding-left: 0px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .TaglineText {
    color: #6ecff6;
    text-align: center;
    width: vw(450px);

    /* Heading/H1 SB 40px */
    font-family: "Overpass";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.6px;
    text-transform: capitalize;
  }
  .WhiteText {
    color: #fff;
    font-weight: 100;
    font-size: 40px;
    line-height: normal;
    letter-spacing: -1.6px;
  }

  .Layer1 {
    top: 0vw;
    height: 100vh;
  }
}

.ContactUsContainer video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  object-fit: cover;
  z-index: -5;
}

.BottomContainer {
  background: #040c1a;
  height: vw(206px);
  width: 100%;
  z-index: 100;
  position: absolute;
}

.TaglineBody {
  width: vw(550px);
  color: #b2c9d5;
  font-family: "Palanquin Dark";
  font-size: vw(18px);
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin-top: vw(40px);
}

.Tagline {
  position: relative;
  width: 60vw;
  padding-left: 7vw;
  padding-top: vw(170px);
}

.TaglineText {
  color: #6ecff6;
  font-family: "Overpass";
  font-size: vw(54px);
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 73.6px */
  letter-spacing: -3.84px;
  text-align: left;
  width: vw(500px);
}

.TaglineButton {
  margin-top: vw(42px);
  width: vw(280px);
  height: vw(54px);
  line-height: vw(10px);
  padding: vw(20px) vw(16px);
  box-sizing: border-box;

  border-radius: vw(88px);
  border: 1px solid #6ecff6;
  cursor: pointer;

  font-family: "Palanquin Dark";
  font-size: vw(14px);
  font-style: normal;
  font-weight: 500;
  // line-height: normal;
  color: #d4effd;
  text-transform: uppercase;
  position: relative;
  z-index: 1000;
}

.TaglineButton:hover {
  background: #6ecff6;
  color: #142943;
}

a {
  text-decoration: none;
  color: #d4effd;
}

.WhiteText {
  color: #d4effd;
  font-family: "Overpass";
  font-size: vw(54px);
  font-style: normal;
  font-weight: 600;
  line-height: 115%;
  letter-spacing: -3.84px;
}

input[type="text"],
input[type="email"],
textarea {
  color: white;
}

.OurMission {
  width: 100vw;
  height: 100vh;
  align-items: center;
  padding-top: 8vw;
  display: flex;
  flex-direction: column;
  gap: vw(48px);
  background: linear-gradient(
    180deg,
    #040c1b 10%,
    #2b3f53 52.67%,
    #537888 100%
  );

  position: relative;
  top: vw(-81px);
  z-index: 1;
}

.MissionContent {
  display: flex;
  flex-direction: column;
  gap: vw(40px);

  font-family: "Overpass";
  font-size: vw(40px);
  font-style: normal;
  line-height: normal;
  text-align: center;
  width: vw(1040px);
}

.MissionHeader {
  color: #fff;
  font-family: "Overpass";
  font-size: vw(38px);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -1.6px;
  text-transform: capitalize;
  // text-align: center;

  .Blue {
    font-weight: 600;
    color: #6ecff6;
  }
}

.MissionBody {
  color: #b2c9d5;
  font-family: "Palanquin Dark";
  font-size: vw(18px);
  font-style: normal;
  font-weight: 400;
  line-height: vw(32px);
  // text-align: center;
}

.VideoSection {
  position: relative;
  width: vw(870px);
  height: vw(450px);
  border-radius: vw(8px);
  overflow: hidden;
}

.MissionVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PartnersAndInvestors {
  margin-top: 8vw;
  .Header {
    // margin-top: vw(206px);
    margin-bottom: vw(120px);
  }
}

.Header {
  color: #1197d5;
  font-family: "Overpass";
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -1.6px;
  text-transform: capitalize;
}

.Blue {
  color: #142943;
  font-weight: 600;
}

.LogoContainer {
  width: vw(798px);
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: vw(80px);
  column-gap: vw(54px);
  margin-bottom: vw(206px);
  align-items: center;
}

// our team
.OurTeamContainer {
  background: linear-gradient(180deg, #bfd1db 0%, #fff 100%);
  // height: vw(1092px);
  top: vw(-81px);
  position: relative;

  .Header {
    padding-top: vw(100px);
    padding-bottom: vw(80px);
  }
}

.TeamImageContainer {
  width: vw(978px);
  display: flex;
  flex-wrap: wrap;
  row-gap: vw(30px);
  margin: auto;
  justify-content: center;
  transform: scale(0.9);
}
.PersonalContainer {
  width: vw(244px);
  height: vw(205px);
}
.PersonalImage {
  width: vw(144px);
  height: vw(144px);
}
.PersonalName {
  color: #2e435d;
  text-align: center;

  /* Small/SB 15px */
  font-family: "Overpass";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 137%; /* 20.55px */
}
.PersonalRole {
  color: #56677c;
  text-align: center;

  /* Caption/Reg 14px */
  font-family: "Overpass";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-transform: uppercase;
}

// carousel

.QuoteTop,
.QuoteBottom {
  color: #142943;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 200;
  width: vw(30px);
}

.QuoteTop {
  // rotate: 180deg;
  position: absolute;
}
.QuoteBottom {
  position: relative;
}
.CarouselContainer {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // width: 100%;
  // overflow: hidden;

  background: linear-gradient(180deg, #537888 0%, #bfd1db 100%);
  // height: 100vh;
  top: vw(-81px);
  position: relative;
  padding-bottom: vw(50px);

  .MissionHeader {
    margin-bottom: vw(98px);
    padding-top: vw(176px);
  }
}

.Carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  // gap: vw(40px);
  height: vw(300px);
  // padding-top: 10vw;
}

.Card {
  width: vw(260px) !important;
  padding: vw(18px) vw(13px);
  height: vw(110px);
  box-sizing: border-box;
  border-radius: vw(6px);
  background: #dbf2fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
  text-align: left;

  transition: all 1.1s cubic-bezier(0.81, 0.01, 0.15, 0.99);

  .QuoteTop {
    // top: -5vw;
    // font-size: 73.89px;
  }
  .QuoteBottom {
    // bottom: 2.5vw;
    left: 16vw;
    // font-size: 73.89px;
  }

  .Review {
    color: #142943;
    font-family: "Palanquin Dark";
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.136px; /* 160% */
  }
  .ReviewAuthor {
    color: #2e435d;
    font-family: "Palanquin Dark";
    font-size: 7px;
    font-style: normal;
    font-weight: 500;
    line-height: 10.673px; /* 144.444% */
    margin-top: vw(22px);
  }
  .ReviewRole {
    color: #56677c;
    font-family: "Palanquin Dark";
    font-size: 6px;
    font-style: normal;
    font-weight: 400;
    line-height: 10.673px; /* 144.444% */
    margin-top: vw(3px);
  }
}

.MagnifiedCard {
  transform: scale(2.1);
  height: vw(110px);

  transition: all 1.1s cubic-bezier(0.81, 0.01, 0.15, 0.99);

  .Review {
    font-size: vw(8px);
    height: vw(35px);
  }

  .ReviewAuthor {
    margin-top: vw(15px);
    font-size: vw(8px);
  }

  .ReviewRole {
    font-size: vw(7px);
  }

  .QuoteTop {
    top: -0.7vw;
    font-size: 80px;
  }
  .QuoteBottom {
    bottom: -0.2vw;
    left: 16.5vw;
    // font-size: 80px;
  }
}

.Navigation {
  display: flex;
  justify-content: center;
  // top: vw(-40px);
  position: relative;
  margin-top: vw(70px);
}

.ArrowButton {
  padding: 10px;
  margin: 0 0px;
  cursor: pointer;
}

.SlickCarousel {
  width: 100vw;
}

.InputField {
  width: vw(519px);
  height: vw(56px);

  padding: 0px 16px 0px 24px;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  margin-bottom: unset;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.32);

  background-color: transparent;
}
::placeholder {
  color: #f1f1f1;

  /* Caption/Reg 14px */
  font-family: "Overpass";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-transform: uppercase;
}
.ContactUsForm {
  display: flex;
  flex-direction: column;
  row-gap: vw(40px);
  margin-top: vw(40px);
  align-items: center;

  .TaglineButton {
    margin-top: unset;
  }
}
.InputContainer {
  display: flex;
  gap: vw(32px);
}

.TextArea {
  height: vw(104px);
  width: 100%;
  padding: 18px 16px 0px 24px;
  gap: 10px;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.32);
  background-color: transparent;
}

input[type="radio"] {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 2px solid #333;
  border: 3px solid white;
  width: 16px;
  height: 16px;
}

input[type="radio"]:checked {
  background: #333;
}
.ContactUsRole {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.ContactUsRadio {
  color: white;
}
.ContactUsText {
  color: #fff;
  margin-bottom: vw(16px);

  /* Paragraph/Reg 16px */
  font-family: "Palanquin Dark";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.RoleContainer {
  display: flex;
  align-items: center;
  label {
    color: white;
    font-family: "Overpass";
    font-size: vw(14px);
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
    margin-left: 1vw;
  }
}

.TaglineAfterClick {
  margin: auto;
  padding-top: 5vw;
  padding-left: 0px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.DisplayNone {
  display: none;
}

@media screen and (max-width: 600px) {
  .Lungs {
    display: none;
  }

  .Layer1 {
    height: 100vh;
    top: 0;
  }
  .HeaderContainer {
    top: -16vw;
    height: 100vh;
  }

  .Tagline {
    width: pvw(358px);
    padding-left: pvw(16px);
    padding-top: pvw(150px);
  }

  .TaglineBody {
    font-size: pvw(18px);
    width: 100%;
  }

  .TaglineText,
  .WhiteText {
    font-size: pvw(48px);
    width: 100%;
  }

  .TaglineButton {
    width: 52vw;
    height: pvw(54px);
    line-height: pvw(40px);
    font-size: 3.5vw;
    margin-top: pvw(32px);
  }

  .OurMission {
    height: pvw(828px);
    top: -16vw;
  }

  .MissionHeader {
    font-size: pvw(32px);
    width: pvw(290px);
    // margin-left: pvw(16px);
    margin-top: pvw(50px);
  }

  .MissionBody {
    font-size: pvw(18px);
    line-height: 32px; /* 177.778% */
    width: pvw(358px);
    margin-top: pvw(24px);
  }

  .MissionContent {
    width: unset;
    text-align: left !important;
  }

  .VideoSection {
    width: pvw(358px);
    height: pvw(232px);
    margin-top: pvw(32px);
  }

  .CarouselContainer {
    height: fit-content;
    // padding-top: pvw(80px);
    top: -29vw;

    .MissionHeader {
      font-size: pvw(32px);
      // margin: auto;
      text-align: left;
      width: pvw(300px);
      padding-bottom: pvw(10px);
      padding-top: 30vw;
      margin-bottom: unset;
      margin-left: pvw(16px);
    }

    .Card {
      width: pvw(242px) !important;
      height: pvw(362px);
      border-radius: 10px;
      box-sizing: border-box;
      padding: pvw(25px) pvw(18px);

      .QuoteTop {
        width: pvw(40px);
        top: -4vw;
        // font-size: 120px;
      }

      .QuoteBottom {
        width: pvw(40px);
        bottom: -5vw;
        left: 42vw;
        font-size: 120px;
      }

      .Review {
        font-size: 18px;
        line-height: 28px;
        height: pvw(232px);
      }
      .ReviewAuthor {
        font-size: 17px;
        margin-top: pvw(22px);
        line-height: unset;
      }
      .ReviewRole {
        font-size: 10px;
        margin-top: 5px;
      }
    }

    .MagnifiedCard {
      transform: scale(1.2);
      .Review {
        font-size: pvw(17px);
        // line-height: 28px;
        min-height: pvw(232px);
      }

      .ReviewAuthor {
        font-size: pvw(16px);
      }
      .ReviewRole {
        font-size: pvw(13px);
      }
    }
  }

  .Navigation {
    // top: -18vw;
    margin-top: pvw(30px);
  }

  .OurTeamContainer {
    padding-left: pvw(16px);
    top: -29vw;
    .Header {
      font-size: pvw(32px);
      padding-top: 10vw;
      padding-bottom: unset;
      // padding-right: pvw(16px);
      text-align: left;
    }
  }

  .TeamImageContainer {
    width: pvw(358px);
    justify-content: space-between;
    row-gap: pvw(64px);
    transform: scale(0.92);

    .PersonalContainer {
      width: pvw(171px);
      height: pvw(246px);

      .PersonalImage {
        width: pvw(144px);
        height: pvw(144px);
      }

      .PersonalName {
        font-size: pvw(15px);
        margin-top: pvw(16px);
      }

      .PersonalRole {
        font-size: pvw(14px);
      }
    }
  }

  .PartnersAndInvestors {
    margin-top: unset;
    padding-bottom: 15vw;
    .Header {
      font-size: pvw(32px);
      width: pvw(300px);
      text-align: left;
      // margin: auto;
      margin-left: pvw(16px);
      margin-bottom: 30vw;
    }
  }

  .LogoContainer {
    width: pvw(330px);
    margin-left: pvw(16px);
    // margin-right: pvw(24px);

    padding-left: pvw(24px);
    // padding-right: pvw(24px);

    column-gap: pvw(30px);
    row-gap: pvw(80px);
    justify-content: space-between;
  }
  .ContactUsContainer {
    height: 182vw;

    .Tagline {
      width: pvw(358px);
      padding-top: pvw(146px);
    }

    .TaglineText,
    .WhiteText {
      font-size: 32px;
      width: 100%;
    }

    .TaglineButton {
      font-size: 18px;
      padding: pvw(20px) pvw(16px);
      line-height: pvw(10px);
      margin-top: pvw(24px);
    }

    .TaglineAfterClick {
      padding-top: 25vw;
    }
  }

  .BottomContainer {
    height: pvw(400px);
  }

  .InputContainer {
    gap: pvw(32px);
  }

  .ContactUsForm {
    row-gap: pvw(24px);
    .TaglineButton {
      width: pvw(358px);
    }
  }

  .ContactUsText {
    font-size: 16px;
    margin-left: pvw(16px);
  }

  .RoleContainer {
    margin-left: pvw(16px);
    margin-top: pvw(16px);
    label {
      font-size: 14px;
      margin-left: 2vw;
      line-height: 5vw;
    }
  }

  .TextArea {
    width: pvw(358px);
    height: pvw(104px);
  }

  .InputField {
    width: pvw(163px);
    height: pvw(56px);
  }
}
