@import "../../convertPxToVw.scss";

.MainContainer {
  background: #142943;
}

.WaveGradient {
  opacity: 0.1;
  position: absolute;
  top: vw(-200px);
  // height: vw(938px);
  width: 100%;
  z-index: 0;
}

.MeshGradient {
  position: fixed;
  top: 0;
  filter: blur(144px);
  animation: moveBackground 20s linear infinite;
  z-index: 0;
}
.MeshGradient2 {
  position: fixed;
  top: 0;
  right: 0;
  filter: blur(144px);
  animation: moveBackground4 20s linear infinite;
  z-index: 0;
}
.MeshGradient3 {
  position: fixed;
  right: 0;
  bottom: vw(-100px);
  filter: blur(144px);
  animation: moveBackground3 20s linear infinite;
  z-index: 0;
}
.MeshGradient1 {
  position: fixed;
  bottom: 0;
  filter: blur(144px);
  animation: moveBackground2 20s linear infinite;
  z-index: 0;
}

@keyframes moveBackground {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 0;
    left: 80vw;
  }
  50% {
    top: 80vh;
    left: 80vw;
  }
  75% {
    top: 80vh;
    left: 0vw;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes moveBackground2 {
  0% {
    top: 80vh;
    left: 0vw;
  }
  25% {
    top: 0;
    left: 0;
  }
  50% {
    top: 0;
    left: 0;
  }
  75% {
    top: 0;
    left: 80vw;
  }
  100% {
    top: 80vh;
    left: 80vw;
  }
}
@keyframes moveBackground3 {
  0% {
    bottom: 0vh;
    right: 0vw;
  }
  25% {
    bottom: 0vh;
    right: 90vw;
  }
  50% {
    bottom: 90vh;
    right: 90vw;
  }
  75% {
    bottom: 90vh;
    right: 0vw;
  }
  100% {
    bottom: 0vh;
    right: 0vw;
  }
}
@keyframes moveBackground4 {
  0% {
    top: 0vh;
    right: 0vw;
  }
  25% {
    top: 80vh;
    right: 0vw;
  }
  50% {
    top: 80vh;
    right: 80vw;
  }
  75% {
    top: 0vh;
    right: 80vw;
  }
  100% {
    top: 0vh;
    right: 0vw;
  }
}

.Header {
  color: #fff;
  text-align: center;
  font-family: "Overpass";
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -1.6px;
  text-transform: capitalize;
  margin-top: vw(107px);
  margin-bottom: vw(107px);

  .Blue {
    color: #6ecff6;
    font-weight: 600;
  }
}

.CardContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: vw(74px);
  justify-content: space-between;
  margin-left: vw(120px);
  width: vw(1040px);
  margin-bottom: vw(160px);
  z-index: 10;
  position: relative;
}

.CardContent {
  padding: vw(24px);
  height: vw(350px);
  display: flex;
  flex-direction: column;
}

.Card {
  width: vw(504px);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  transition: transform 0.3s ease;
  transform: scale(0.9);

  .CardImage {
    width: 100%;
    object-fit: cover;
    height: vw(230px);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.Card:hover {
  border-radius: 12px;
  background: #142943;
  box-shadow: 0px 7px 29px 0px rgba(0, 0, 0, 0.31);
  transition: transform 0.3s ease;
  .CardImage {
    // transform: scale(1.05);
  }
}

.Card:hover .ReadMoreButton {
  width: 50px;
  height: 50px;
  transition: width 0.3s ease, height 0.3s ease;
  transform-origin: bottom left;
}

.CardDate {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Overpass";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 137%;
  margin-top: vw(16px);
  margin-bottom: vw(16px);
}
.CardHeader {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: #85d7f7;
  font-family: "Overpass";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 133.333% */
}
.CardDescription {
  color: #fff;
  font-family: "Palanquin Dark";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.CardFooter {
  color: #6ecff6;
  font-family: "Palanquin Dark";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  display: flex;
  justify-content: space-between;
  height: vw(50px);
  margin-top: auto;
  img {
    width: 0px;
    height: 0px;
  }
}

@media screen and (max-width: 600px) {
  .WaveGradient {
    top: -8vw;
  }

  .Header {
    font-size: pvw(36px);
  }

  .MainContainer {
    height: fit-content;
  }
  
  .CardContainer {
    flex-direction: row;
    margin-left: unset;
    margin: auto;
    width: pvw(358px);
    height: fit-content;
    margin-bottom: pvw(50px);
  }

  .Card {
    width: 100%;
    height: 100%;

    .CardContent {
      margin: pvw(17px);
      margin-top: pvw(32px);
      height: unset;
    }

    .CardHeader {
      font-size: pvw(30px);
    }

    .CardDate {
      font-size: pvw(15px);
      margin-top: pvw(16px);
      margin-bottom: pvw(16px);
    }

    .CardDescription {
      font-size: pvw(16px);
    }

    .CardFooter {
      font-size: pvw(16px);
      margin-top: pvw(10px);
      height: unset;
    }
    .CardImage {
      height: pvw(194px);
    }
  }
}
