@import "../../convertPxToVw.scss";

.NavBar {
  display: flex;
  position: relative;
  justify-content: space-between;
  z-index: 100;
  font-family: "Palanquin Dark";
  align-items: center;
  padding: 32px 120px 16px 120px;

  background-color: transparent;
  box-sizing: border-box;
  z-index: 20;

  transition: all 0.3s ease-out;
}

.Hamburger {
  display: none;
}

.SwazaLogo {
  width: 120px;
  // height: 32px;
}

.HamburgerOptions {
  display: none;
}

.NavBarOptionText:hover {
  color: #6ECFF6;
}

.NavBarOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 3vw;
  font-size: vw(16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  a {
    text-decoration: none;
  }

  .selected {
    color: #1197d5;
  }
}

.NavBarOptionText {
  color: white;
}

.NavBarOptionTextSelected {
  color: #1197D5;
}

@media screen and (max-width: 600px) {
  .NavBarOptions {
    display: none;
  }

  .HamburgerOptions {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: pvw(284px);
    position: absolute;
    z-index: 10000;
    text-align: center;
    left: 0;
    border-radius: 3px;
    background: #022145;
    justify-content: flex-start;
    gap: pvw(16px);
    padding-top: pvw(18px);
    padding-bottom: pvw(37px);
    top: 13vw;
  }

  .HamburgerOptionText {
    color: #fff;

    /* Paragraph/Reg 18px */
    font-family: "Palanquin Dark";
    font-size: pvw(18px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .HamburgerOptions a {
    text-decoration: none;
  }

  .Hamburger {
    display: block;
    width: 5vw !important;
    height: 4.2vw;
  }

  .NavBar {
    padding: pvw(16px);
  }

  .SwazaLogo {
    width: pvw(107px) !important;
    height: pvw(21px);
  }
}
