.AdminContainer {
  padding: 20px;
}

.Table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

input {
  display: block;
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  max-width: 300px;
}

button {
  margin-bottom: 20px;
  padding: 5px 10px;
  cursor: pointer;
}
