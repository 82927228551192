@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: "Palanquin Dark";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {
}
