@import "../../convertPxToVw.scss";

.MainContainer {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: vw(32px);
  width: vw(1040px);
  margin: auto;

  position: relative;
  z-index: 100;
}

.StepsContainerBorder {
  width: 100%;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.02) 0%,
    rgba(153, 153, 153, 0.98) 53.82%,
    rgba(255, 255, 255, 0.02) 100%
  );
  height: 1px;
  border: none;
}

.Follow {
  color: #00a3ed;
  text-align: center;
  font-family: "Jost";
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3vw; /* 160% */
  letter-spacing: 1.35px;
  /* width: 15.3vw; */
}

.SocialLogo {
  width: 1.7vw;
  height: 1.7vw;
}

.Navigation {
  display: flex;
  flex-direction: row;
  width: vw(400px);
  justify-content: space-between;

  color: #fff;
  font-family: "Palanquin Dark";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Info {
  display: flex;
  width: vw(1040px);
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: vw(55px);
}

.Logo {
  align-items: center;
  width: vw(172px);
  height: vw(32px);
}

.Copyright {
  margin-top: vw(48px);
  color: #fff;
  text-align: center;

  /* Small/SB 15px */
  font-family: "Overpass";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 137%; /* 20.55px */
}

.Navigation a {
  text-decoration: none;
}

.SocialLogoContainer {
  width: vw(128px);
  display: flex;
}

@media screen and (max-width: 600px) {
  
  .MainContainer {
    height: 98.6vw;
  }
  .Logo {
    width: 27.2vw;
    height: 7.4vw;
  }

  .Info {
    flex-direction: column;
    padding-top: 5.3vw;
    height: auto;
    padding-bottom: 9vw;
    width: 85.8vw;
    justify-content: flex-start;
  }
  .Navigation {
    flex-direction: column;
    margin-left: 0;
    height: 35vw;
    width: 100%;
    justify-content: center;
    row-gap: 3.2vw;
    padding-top: 7vw;
    text-align: left;
  }

  .Row {
    font-size: 3.2vw;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.24px;
    row-gap: 3.2vw;
  }

  .SocialLinks {
    padding-top: 8vw;
    width: 100%;
    margin-left: 0;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }

  .Follow {
    font-size: 3vw;
    text-align: left;
    font-weight: 700;
    line-height: 4px;
    width: 100%;
    letter-spacing: 0.99px;
  }

  .SocialLogo {
    width: pvw(32px);
    height: pvw(32px);
  }

  .SocialLogoContainer {
    flex-direction: column;
    padding-top: pvw(40px);
    // padding-bottom: pvw(32px);
    width: 100%;
    align-items: center;
  }

  .Copyright {
    font-family: "Overpass";
    font-size: 15px;
    margin: unset;
  }
}
