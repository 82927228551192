@import "../../convertPxToVw.scss";

.MainContainer {
  background: #142943;
}

.WaveGradient {
  opacity: 0.1;
  position: absolute;
  top: vw(-200px);
  // height: vw(938px);
  width: 100%;
  z-index: 0;
}

.MeshGradient {
  position: fixed;
  top: -15vw;
  filter: blur(144px);
  animation: moveBackground 20s linear infinite;
  z-index: 0;
}
.MeshGradient2 {
  position: fixed;
  top: -5vw;
  right: -5vw;
  filter: blur(144px);
  animation: moveBackground4 20s linear infinite;
  z-index: 0;
}
.MeshGradient3 {
  position: fixed;
  right: -15vw;
  bottom: -15vw;
  filter: blur(144px);
  animation: moveBackground3 20s linear infinite;
  z-index: 0;
}
.MeshGradient1 {
  position: fixed;
  bottom: -15vw;
  filter: blur(144px);
  animation: moveBackground2 20s linear infinite;
  z-index: 0;
}

@keyframes moveBackground {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 0;
    left: 80vw;
  }
  50% {
    top: 80vh;
    left: 80vw;
  }
  75% {
    top: 80vh;
    left: 0vw;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes moveBackground2 {
  0% {
    top: 80vh;
    left: 0vw;
  }
  25% {
    top: 0;
    left: 0;
  }
  50% {
    top: 0;
    left: 0;
  }
  75% {
    top: 0;
    left: 80vw;
  }
  100% {
    top: 80vh;
    left: 80vw;
  }
}
@keyframes moveBackground3 {
  0% {
    bottom: 0vh;
    right: 0vw;
  }
  25% {
    bottom: 0vh;
    right: 90vw;
  }
  50% {
    bottom: 90vh;
    right: 90vw;
  }
  75% {
    bottom: 90vh;
    right: 0vw;
  }
  100% {
    bottom: 0vh;
    right: 0vw;
  }
}
@keyframes moveBackground4 {
  0% {
    top: 0vh;
    right: 0vw;
  }
  25% {
    top: 80vh;
    right: 0vw;
  }
  50% {
    top: 80vh;
    right: 80vw;
  }
  75% {
    top: 0vh;
    right: 80vw;
  }
  100% {
    top: 0vh;
    right: 0vw;
  }
}

.HeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: vw(120px);
  position: relative;
  z-index: 10;
}
.Header {
  color: #fff;
  text-align: center;
  font-family: "Overpass";
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -1.6px;
  text-transform: capitalize;
  z-index: 20;
  width: vw(672px);
  margin: auto;

  .Blue {
    color: #6ecff6;
    font-weight: 600;
  }
}

.SubHeader {
  color: #b2c9d5;
  text-align: center;
  font-family: "Palanquin Dark";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  width: 884px;
  z-index: 20;
  margin-top: vw(88px);
}

.FeatureContainer {
  display: flex;
  flex-direction: column;
  width: vw(1040px);
  margin: auto;
  margin-top: vw(175px);
  z-index: 20;
  position: relative;
}

.EachFeatureContainer {
  display: flex;
  justify-content: space-between;
  padding-top: vw(43px);
  padding-bottom: vw(40px);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  z-index: 20;
}

.FeatureHeader {
  width: vw(288px);

  color: #37a8dc;
  font-family: "Palanquin Dark";
  font-size: vw(24px);
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  z-index: 20;
}

.FeatureSubHeader {
  width: vw(507px);

  color: #fff;
  font-family: "Palanquin Dark";
  font-size: vw(18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 20;
}

.ApplicationContainer {
  margin-top: vw(178px);
  z-index: 20;
}

.Applications {
  margin: auto;
  display: flex;
  justify-content: center;
  column-gap: vw(50px);
  align-items: center;
  row-gap: vw(50px);
  // column-gap: vw(104px);
  flex-wrap: wrap;
  // width: vw(880px);
  margin: vw(200px);
  margin-top: vw(70px);
  z-index: 20;
}

.EachApplicationContainer {
  display: flex;
  width: vw(224px);
  height: vw(224px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  z-index: 20;
  border-radius: 313px;
  border: 1px solid rgba(1, 196, 255, 0.19);

  transform: scale(0.9);
}

.EachApplicationContainer:hover {
  background: #1197d5;
  box-shadow: 0px 0px 50.1px 0px rgba(162, 164, 166, 0.36),
    0px 0px 25.4px 0px rgba(162, 164, 166, 0.43);
}

.ApplicationImage {
  width: vw(80px);
}

.ApplicationHeader {
  width: vw(170px);
  color: #fff;
  text-align: center;
  font-family: "Palanquin Dark";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.ProductContainer {
  margin-top: vw(160px);
  padding-bottom: 30px;
}

.Products {
  display: flex;
  width: vw(1040px);
  justify-content: space-between;
  margin: auto;
  margin-top: vw(85px);
}

.EachProductContainer {
  width: vw(189px);
  min-height: vw(390px);
  transition: all 0.8s cubic-bezier(0.81, 0.01, 0.15, 0.99);
  top: 0;
  border-radius: 8px;
  z-index: 20;
}

.EachProductContainer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1197d5;
  clip-path: circle(0%);
  border-radius: 20px;
  transition: 0.6s cubic-bezier(0.45, 0, 0.57, 0.99);
  z-index: -1;
}

.EachProductContainer:hover::after {
  clip-path: circle(100%);
}

.EachProductContainer:hover {
  position: relative;
  top: vw(-40px);
  width: vw(270px);
  border-radius: 18px;
  transition: display 1s ease-in-out;

  .EachProductContainer .ProductImage {
    width: vw(177px);
  }

  .ProductHeader {
    color: #142943;
    padding-left: vw(16px);
    width: vw(177px);
  }

  .ProductFeatures {
    margin-left: vw(16px);
    display: block;
  }
}

.Products:hover .EachProductContainer:not(:hover) .ProductImage {
  transform: scale(0.9);
  transition: all 0.1s cubic-bezier(0.81, 0.01, 0.15, 0.99);
}

.Navigation {
  position: relative;
  z-index: 100;

  display: flex;
  margin-top: 11vw;
  gap: 5vw;
  justify-content: center;
}

.ProductImage {
  width: 100%;
  border-radius: 8px;
}

.ProductHeader {
  color: #1197d5;
  font-family: "Overpass";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.72px;
  margin-top: vw(15px);
}

.AccordionContainer {
  display: none;
}

ul {
  margin-block: 0 !important;
  padding-inline-start: 15px !important;
}

.ProductFeatures {
  display: none;
  color: #fff;
  font-family: "Palanquin Dark";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
  border-radius: 582px;
  width: vw(204px);
}

.MobileProducts {
  display: none;
}

@media screen and (max-width: 600px) {
  .WaveGradient {
    top: 0px;
  }

  .HeaderContainer {
    width: pvw(358px);
    margin: auto;
    margin-top: pvw(40px);
    .Header {
      font-size: pvw(36px);
      width: 90%;
    }

    .SubHeader {
      font-size: pvw(18px);
      width: 100%;
      text-align: center;
    }
  }

  .FeatureContainer {
    display: none;
  }

  .AccordionContainer {
    display: block;
    width: 100%;
    margin-top: pvw(80px);
    position: relative;
    z-index: 100;
    border-top: 0.5px solid rgba(202, 202, 202, 0.22);
  }

  .AccordionItem {
    border-bottom: 0.5px solid rgba(202, 202, 202, 0.22);
  }

  .AccordionHeader {
    display: flex;
    justify-content: space-between;
    padding: pvw(16px);
    color: #37a8dc;
    font-family: "Palanquin Dark";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    cursor: pointer;
  }

  .AccordionIcon {
    font-size: 18px;
  }

  .AccordionContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.1s ease;
    color: #fff;
    padding: 0 10px;
  }

  .Active {
    max-height: fit-content;
    width: pvw(356px);
    padding: pvw(16px);
  }

  .ApplicationContainer {
    width: 100vw;
    margin-top: pvw(120px);
    // margin-left: pvw(16px);
    .Header {
      width: 87%;
      margin: auto;
      margin-bottom: pvw(62px);
      font-size: pvw(32px);
      text-align: center;
    }

    .Applications {
      margin: auto;
      width: pvw(358px);
      align-items: flex-start;
    }
  }

  .EachApplicationContainer {
    width: pvw(171px);
    gap: pvw(10px);
    height: unset;
    border: unset;

    .ApplicationImage {
      width: pvw(80px);
      height: pvw(80px);
    }

    .ApplicationHeader {
      font-size: pvw(16px);
      width: 100%;
    }
  }

  .EachApplicationContainer:hover {
    background: unset;
    box-shadow: unset;
  }

  .ProductContainer {
    margin-top: pvw(120px);
    margin-bottom: pvw(56px);
    .Header {
      width: unset;
      font-size: pvw(32px);
      text-align: center;
    }
  }

  .Products {
    display: none;
  }

  .MobileProducts {
    display: block;
    .EachMobileProductContainer {
      width: pvw(236px) !important;
      height: pvw(348px);
      background: #1197d5;

      border-radius: 10px;

      .MobileProductImage {
        width: pvw(236px);
        height: pvw(147px);
        border-radius: 8px;
      }

      .MobileProductHeader {
        color: #142943;
        font-family: "Overpass";
        font-size: pvw(24px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        margin-top: pvw(15px);
        margin-left: pvw(16px);
      }

      .ProductFeatures {
        display: block;
        width: pvw(204px) !important;
        margin-left: pvw(17px);
      }
    }
  }
}
